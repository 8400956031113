.header-container {
    margin: 0;
    padding: 0;
}

.header-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.header-image {
    width: 80%;
    height: 50%;
    padding-top: 5%;
    margin-left: 30%;
}

.header-text {
    font-size: xx-large;
    width: 50%;
    margin-left: 30%;
    padding-bottom: 10%;
}

.header-button {
    background-color: #E6C068;
    font-size: x-large;
    width: 40%;
    margin-left: 35%;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border: none;
    padding-top: 1%;
    padding-bottom: 2%;
}

/*Tablet*/
@media screen and (min-width:768px) and (max-width:1023px) {
    .header-image {
        width: 80%;
        margin-left: 15%;
    }

    .header-text {
        width: 80%;
        font-size: larger;
        margin-left: 10%;
    }

    .header-button {
        font-size: larger;
        width: 55%;
        margin-left: 20%;
    }
}

/*Phone*/
@media (max-width: 766px) {
    .header-image {
        width: 70%;
        margin-left: 15%;
    }

    .header-text {
        width: 70%;
        font-size: medium;
        margin-left: 0%;
        padding-bottom: 2%;
    }

    .header-button {
        width: 70%;
        font-size: medium;
        margin-left: 5%;
    }

    .image-header-container {
        margin: 0%;
    }
}