.about-grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.about-heading {
    text-align: center;
    font-size: xx-large;
    padding: 2%;
}

.about-text {
    font-size: x-large;
    width: 60%;
    margin-left: 30%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.about-text1 {
    font-size: x-large;
    width: 60%;
    margin-left: 30%;
    padding-bottom: 5%;
}

.homepage-about-image {
    width: 80%;
    height: 80%;
}

.about-button {
    background-color: #E6C068;
    font-size: x-large;
    width: 40%;
    margin-left: 40%;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border: none;
    padding-top: 1%;
    padding-bottom: 2%;
}

/*Tablet*/
@media screen and (min-width:768px) and (max-width:1023px) {
    .about-heading {
        margin-top: 2%;
    }

    .about-text {
        font-size: larger;
        width: 80%;
        margin-left: 20%;
        padding-top: 0%;
        padding-bottom: 2%;
    }

    .about-text1 {
        margin-top: 5%;
        font-size: larger;
        width: 80%;
        margin-left: 20%;
        padding-bottom: 2%;
    }

    .homepage-about-image {
        margin-left: 10%;
    }

    .about-button {
        margin-top: 5%;
        font-size: larger;
    }
}

/*Phone*/
@media (max-width: 766px) {
    .about-heading {
        font-size: large;
    }

    .about-text {
        font-size: medium;
        width: 90%;
        margin-left: 10%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .about-text1 {
        font-size: medium;
        width: 80%;
        margin-left: 10%;
        padding-bottom: 5%;
    }

    .about-button {
        font-size: large;
        width: 60%;
        margin-left: 20%;
    }

    .homepage-about-image {
        margin: 5%;
    }
}