/*Tablet*/
@media screen and (min-width:768px) and (max-width:1023px) {
    .LogIn-img {
        margin-top: 35%;
        width: 50%;
        height: 50%;
    }
}

/*Phone*/
@media (max-width: 766px) {
    .LogIn-form {
        width: 100vw;
    }

    .LogIn-img {
        display: none;
    }

    .text-shadow {
        text-shadow: 0 0 2px white;
    }
}