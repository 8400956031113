.TheTestimonial {
    text-align: center;
    padding-top: 5%;
    font-size: xx-large;
}

.testimonial-container {
    width: 60%;
    font-size: larger;
    display: flex;
    margin: 3% 20%;
    position: relative;
    overflow: hidden;
}

.testimonial-slide {
    display: flex;
    transition: transform 0.5s ease;
}

.Testimonial-content {
    display: flex;
    flex-direction: column;
}

.Testimonial-content>div {
    display: none;
    padding: 20px;
    background-color: #ffffff;
}

.Testimonial-content>div.active {
    display: block;
    text-align: center;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    /* White background */
    color: #333;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 16px;
}

.arrow-left {
    left: 0;
}

.arrow-right {
    right: 0;
}

.testimonial-button {
    width: 25%;
    margin-left: 38%;
    margin-top: 2%;
    background-color: #E6C068;
    font-size: x-large;
    width: 20%;
    margin-left: 40%;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border: none;
    padding-top: 1%;
    padding-bottom: 1%;
}