.quality-banner {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    margin-top: 5%;
}

.quality-logo {
    margin-left: 30%;
}

.quality-grid {
    margin-top: 10%;
    display: flex;
    gap: 60%;
}

.quality-grid-section {
    display: block;
}

.quality-grid-image {
    margin-left: 45%;
    height: 50px;
    width: 50px;
}

.quality-banner-text {
    font-size: large;
    margin-left: 30%;
    width: 100%;
    text-align: center;
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .quality-banner {
        display: grid;
        grid-template-columns: 35% 15% 15% 15%;
    }
}

/*Phone*/
@media (max-width: 766px) {
    .quality-logo {
        margin-left: 0%;
        margin-top: 0%;
        scale: 0.8;
    }

    .quality-grid {
        margin-top: 35%;
        gap: 25%;
    }

    .quality-banner-text {
        font-size: small;
        width: 100%;
    }

    .quality-grid-image {
        margin-left: 25%;
        height: 50px;
        width: 50px;
    }
}