.boards-heading1 {
    text-align: center;
    padding-top: 0%;
    font-size: xx-large;
}

.boards-heading2 {
    text-align: center;
    padding-bottom: 5%;
    font-size: x-large;
}


.boards-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 10%;
    gap: 5%
}

.boards-image {
    height: 90%;
    width: 90%;
}

.boards-button {
    margin-top: 2%;
    background-color: #E6C068;
    font-size: x-large;
    width: 20%;
    margin-left: 40%;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border: none;
    padding-top: 1%;
    padding-bottom: 1%;
}

/*Tablet*/
@media screen and (min-width:768px) and (max-width:1023px) {
    .boards-button {
        font-size: larger;
        width: 25%;
        margin-left: 38%;
    }
}

/*Phone*/
@media (max-width: 766px) {
    .boards-grid {
        margin: 0 5%;
        gap: 2.5%
    }

    .boards-heading1 {
        font-size: large;
    }

    .boards-heading2 {
        font-size: medium;
    }

    .boards-button {
        font-size: large;
        width: 40%;
        margin-left: 30%;
    }
}