@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://cdn.syncfusion.com/ej2/material.css');

* {
  font-family: 'Playfair Display';
}