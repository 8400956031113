.delivery-banner {
    background-color: #F0F0F0;
}

.delivery-banner-text {
    font-size: large;
    text-align: center;
    margin: 0;
    padding-bottom: 1%;
}

/*Tablet*/
@media screen and (min-width:768px) and (max-width:1023px) {}

/*Phone*/
@media (max-width: 766px) {
    .delivery-banner-text {
        font-size: medium;
    }
}